import React from 'react'
import axios from 'axios'
import Dashboard from './pages/dashboard.jsx'
import NavBar from './components/navbar.jsx'
import Footer from './components/footer.jsx'

function App() {
    let uuid = window.location.pathname.replace('/', '')
    const [premise, setPremise] = React.useState({})
    React.useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/premise/${uuid}`)
            .then((response) => {
                setPremise(response.data)
            })
    }, [uuid])

    return (
        <div>
            <NavBar premise={premise} />
            <div className="container-fluid">
                <Dashboard premise={premise} />
            </div>
            <Footer />
        </div>
    )
}

export default App
